import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';

import {TSampleDetailsModel, TBlendDetailsModel} from 'core/models';

import {BlendForms, SampleForms} from './components';

interface IProps {
  sampleInstance: TSampleDetailsModel | null;
  blendInstance: TBlendDetailsModel | null;
}

// TODO: Refactoring, see ProjectForms.tsx in TechProcess.
// Use BaseJsForms instead of SamplesForms and BlendForms
const SamplesFormsFC: FC<IProps> = ({sampleInstance, blendInstance}) => {
  return (
    <Box p="12px 8px">
      {!!sampleInstance && <SampleForms sampleInstance={sampleInstance} />}
      {!!blendInstance && <BlendForms blendInstance={blendInstance} />}
    </Box>
  );
};

export const SamplesForms = observer(SamplesFormsFC);
