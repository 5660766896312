import {Box, Link} from '@chakra-ui/react';
import {ChevronLeftIcon} from '@chakra-ui/icons';
import {observer} from 'mobx-react-lite';
import {FC} from 'react';

interface IProps {
  name: string;
  onBack: () => void;
}

const BackToEntityFC: FC<IProps> = ({name, onBack}) => {
  return (
    <Box p="0 0 4px 0">
      <Link
        onClick={onBack}
        color="accent"
        display="flex"
        alignItems="center"
        _hover={{textDecoration: 'none'}}
      >
        <ChevronLeftIcon boxSize="20px" />
        {name}
      </Link>
    </Box>
  );
};

export const BackToEntity = observer(BackToEntityFC);
