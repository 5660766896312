import {FC, useEffect, useRef, useState, DragEvent} from 'react';
import {Box, Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EElement, EventEmitter, TFlowType, useT} from '@progress-fe/core';
import {useDialog} from '@progress-fe/ui-kit';
import {
  Node,
  Edge,
  XYPosition,
  EdgeChange,
  NodeChange,
  Connection,
  ReactFlowInstance,
  FinalConnectionState,
  useEdgesState,
  useNodesState,
  useStoreApi
} from '@xyflow/react';
import {
  ERFElement,
  IRFMenuItem,
  IRFNodePort,
  TRFEdgeDataConfig,
  TRFWorkZoneDataConfig,
  RFRender,
  isFlowNode,
  isEnergyPort,
  createPortCode,
  ElementByRFElement,
  RF_DRAG_NODE_TYPE,
  RF_DRAG_TEMPLATE_CODE,
  RF_FIT_VIEW_MAX_ZOOM,
  isConnectionValid
} from '@progress-fe/rf-core';

import {RFMenu} from 'ui-kit';

interface IProps {
  width: number;
  height: number;
  menuItems: IRFMenuItem[];
  initialNodes: Node<TRFWorkZoneDataConfig>[];
  initialEdges: Edge<TRFEdgeDataConfig>[];
  isSubWorkzone: boolean;
  selectedNodeId: string | null;
  selectedSubNodeId: string | null;
  onCreateElement: (type: EElement, template: string, pos?: XYPosition) => Promise<string | null>;
  onDeleteElement: (uuid: string) => Promise<void>;
  onChangeNodePosition: (uuid: string, position: XYPosition) => Promise<void>;
  onConnectElements: (from: IRFNodePort, to: IRFNodePort) => Promise<void>;
  onDisconnectElements: (from: IRFNodePort, to: IRFNodePort) => Promise<void>;
}

const RFWorkZoneFC: FC<IProps> = ({
  width,
  height,
  menuItems,
  initialNodes,
  initialEdges,
  isSubWorkzone,
  selectedNodeId,
  selectedSubNodeId,
  onCreateElement,
  onDeleteElement,
  onConnectElements,
  onDisconnectElements,
  onChangeNodePosition
}) => {
  const pickedNodeId = useRef<string | null>(null);

  const [instance, setInstance] = useState<ReactFlowInstance<
    Node<TRFWorkZoneDataConfig>,
    Edge<TRFEdgeDataConfig>
  > | null>(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([] as Node<TRFWorkZoneDataConfig>[]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge<TRFEdgeDataConfig>[]);

  const {getState} = useStoreApi();
  const {addSelectedNodes, resetSelectedElements} = getState();

  const {t} = useT();

  const DeleteNodeDialog = useDialog({title: t('elements.delete')});
  const DeleteEdgeDialog = useDialog({title: t('connections.delete')});

  useEffect(() => {
    setNodes(initialNodes);
  }, [initialNodes, initialNodes.length, setNodes]);

  useEffect(() => {
    setEdges(initialEdges);
  }, [initialEdges, initialEdges.length, setEdges]);

  useEffect(() => {
    instance?.fitView({maxZoom: RF_FIT_VIEW_MAX_ZOOM});
  }, [width, instance]);

  useEffect(() => {
    const pickEntity = (id: string) => {
      pickedNodeId.current = id;
      const foundNode = nodes.find((n) => n.id === id);
      if (!!foundNode) addSelectedNodes([foundNode.id]);
    };

    EventEmitter.on('PickItem', pickEntity);

    return () => {
      EventEmitter.off('PickItem', pickEntity);
    };
  }, [addSelectedNodes, nodes]);

  useEffect(() => {
    setTimeout(() => {
      if (!!selectedSubNodeId) {
        addSelectedNodes([selectedSubNodeId]);
      } else if (!!selectedNodeId) {
        addSelectedNodes([selectedNodeId]);
      } else {
        resetSelectedElements();
      }
    }, 50);
    // FYI: Should be only 1 dep
    // eslint-disable-next-line
  }, [selectedNodeId, selectedSubNodeId]);

  const handleSelectNode = (nodeId: string) => {
    // Don't emit event when node was selected by the Pick button
    if (nodeId !== pickedNodeId.current) {
      const node = nodes.find((n) => n.id === nodeId);
      if (!node?.data.isNotElement && !!node?.data.isSubElement && !!selectedNodeId) {
        EventEmitter.emit('SelectItem', selectedNodeId, nodeId);
      } else if (!node?.data.isNotElement) {
        EventEmitter.emit('SelectItem', nodeId);
      }
    }
    // Just clear node which was selected by the Pick button
    if (nodeId === pickedNodeId.current) {
      pickedNodeId.current = null;
    }
  };

  const handleNodeChanges = async (changes: NodeChange<Node<TRFWorkZoneDataConfig>>[]) => {
    if (changes.every((c) => c.type === 'dimensions')) {
      onNodesChange(changes);
    } else if (changes.every((c) => c.type === 'select')) {
      onNodesChange(changes);
      const targetChange = changes.find((n) => n.selected);
      if (!!targetChange) {
        handleSelectNode(targetChange.id);
      }
    } else if (changes.every((c) => c.type === 'position')) {
      console.info('[position]', changes);
      onNodesChange(changes);
      const targetChange = changes.find((n) => n.dragging === false);
      if (!!targetChange?.position) {
        await onChangeNodePosition(targetChange.id, targetChange.position);
      }
    }
  };

  const handleEdgeChanges = (changes: EdgeChange<Edge<TRFEdgeDataConfig>>[]) => {
    if (changes.every((c) => c.type === 'select')) {
      onEdgesChange(changes);
    }
  };

  /* Remove nodes and/or edges */
  const handleOnBeforeDelete = (
    nodesForRemove: Node<TRFWorkZoneDataConfig>[],
    edgesForRemove: Edge<TRFEdgeDataConfig>[]
  ) => {
    if (nodesForRemove.length === 1) {
      console.info('[WorkZone] On delete node.');
      const name = nodesForRemove[0].data?.elementName;
      DeleteNodeDialog.open(t('elements.deleteSureMsg', {name}), {
        close: {title: t('actions.cancel')},
        apply: {
          title: t('actions.delete'),
          isDanger: true,
          onClick: async () => {
            for (const edge of edgesForRemove) {
              const from: IRFNodePort = {nodeUuid: edge.source, portCode: edge.sourceHandle || ''};
              const to: IRFNodePort = {nodeUuid: edge.target, portCode: edge.targetHandle || ''};
              await onDisconnectElements(from, to);
            }
            await onDeleteElement(nodesForRemove[0].id);
          }
        }
      });
      return;
    }

    if (edgesForRemove.length === 1) {
      console.info('[WorkZone] On delete edge.');
      DeleteNodeDialog.open(t('connections.deleteSureMsg'), {
        close: {title: t('actions.cancel')},
        apply: {
          title: t('actions.delete'),
          isDanger: true,
          onClick: async () => {
            const edge = edgesForRemove[0];
            const from = {nodeUuid: edge.source, portCode: edge.sourceHandle || ''};
            const to = {nodeUuid: edge.target, portCode: edge.targetHandle || ''};
            await onDisconnectElements(from, to);
          }
        }
      });
      return;
    }
  };

  /* Connect two elements */
  const handleOnConnect = async (connection: Connection) => {
    if (isConnectionValid(connection, nodes)) {
      const {source, target, sourceHandle, targetHandle} = connection;

      const sourceNode = nodes.find((n) => n.id === source);
      const targetNode = nodes.find((n) => n.id === target);

      const isEnergy = isEnergyPort(sourceHandle);

      // One of nodes must be as NON-flow node
      if (isFlowNode(sourceNode?.type) || isFlowNode(targetNode?.type)) {
        console.info('[WorkZone] On connect.');
        const from = {nodeUuid: sourceNode?.id || '', portCode: sourceHandle || ''};
        const to = {nodeUuid: targetNode?.id || '', portCode: targetHandle || ''};
        await onConnectElements(from, to);
      }
      // Create flow node between nodes
      else {
        console.info('[WorkZone] On connect with flow creation.');
        if (!!sourceNode?.position && targetNode?.position) {
          const betweenX = (sourceNode.position.x + targetNode.position.x) / 2;
          const betweenY = (sourceNode.position.y + targetNode.position.y) / 2;

          const betweenPosition: XYPosition = {x: betweenX, y: betweenY};
          const elementType = isEnergy ? EElement.EnergyFlowElement : EElement.MaterialFlowElement;

          const flowNodeId = await onCreateElement(elementType, 'default', betweenPosition);
          if (!!flowNodeId && !!sourceHandle && !!targetHandle) {
            const flowType: TFlowType = isEnergy ? 'energy' : 'material';

            const fromA = {nodeUuid: source, portCode: sourceHandle};
            const toA = {nodeUuid: flowNodeId, portCode: createPortCode('target', flowType)};
            await onConnectElements(fromA, toA);

            const fromB = {nodeUuid: flowNodeId, portCode: createPortCode('source', flowType)};
            const toB = {nodeUuid: target, portCode: targetHandle};
            await onConnectElements(fromB, toB);
          }
        }
      }
    }
  };

  /* Reconnect two elements */
  const handleOnReconnect = async (oldEdge: Edge<TRFEdgeDataConfig>, newConnection: Connection) => {
    console.info('[WorkZone] On reconnect.');
    const {source, target, sourceHandle, targetHandle} = oldEdge;

    const oldFrom = {nodeUuid: source, portCode: sourceHandle || ''};
    const oldTo = {nodeUuid: target, portCode: targetHandle || ''};
    await onDisconnectElements(oldFrom, oldTo);

    await handleOnConnect(newConnection);
  };

  /* User drops connect line without valid connection */
  const handleOnConnectDrop = async (
    event: MouseEvent | TouchEvent,
    state: FinalConnectionState
  ) => {
    const {isValid, fromNode, fromHandle} = state;

    // It should be processed when isValid is false only.
    if (!isValid && !!fromHandle && !!fromNode && !isFlowNode(fromNode.type)) {
      console.info('[WorkZone] On drop connection.');

      const existingConnections =
        instance?.getHandleConnections({
          id: fromHandle.id,
          nodeId: fromHandle.nodeId,
          type: fromHandle.type
        }) || [];

      if (existingConnections.length > 0) {
        console.info('[WorkZone] Break on drop connection.');
        return;
      }

      const isEnergy = isEnergyPort(fromHandle.id);
      const flowType: TFlowType = isEnergy ? 'energy' : 'material';
      const elementType = isEnergy ? EElement.EnergyFlowElement : EElement.MaterialFlowElement;

      const {clientX, clientY} = 'changedTouches' in event ? event.changedTouches[0] : event;
      const position = instance?.screenToFlowPosition({x: clientX, y: clientY});
      const flowNodeId = await onCreateElement(elementType, 'default', position);

      if (!!flowNodeId) {
        const isSource = fromHandle.type === 'source';

        const from = isSource
          ? {nodeUuid: fromHandle.nodeId, portCode: fromHandle.id || ''}
          : {nodeUuid: flowNodeId, portCode: createPortCode('source', flowType)};

        const to = isSource
          ? {nodeUuid: flowNodeId, portCode: createPortCode('target', flowType)}
          : {nodeUuid: fromHandle.nodeId, portCode: fromHandle.id || ''};

        await onConnectElements(from, to);
      }
    }
  };

  /* Create a new element */
  const handleOnCreate = async (event: DragEvent<HTMLDivElement>) => {
    console.info('[WorkZone] On create.');
    event.preventDefault();

    const rfNodeType = event.dataTransfer.getData(RF_DRAG_NODE_TYPE);
    const templateCode = event.dataTransfer.getData(RF_DRAG_TEMPLATE_CODE);
    const position = instance?.screenToFlowPosition({x: event.clientX, y: event.clientY});

    if (!!position && !!rfNodeType) {
      const elementType = ElementByRFElement[rfNodeType as ERFElement];
      if (!!elementType) {
        await onCreateElement(elementType, templateCode, position);
      }
    }
  };

  return (
    <Grid width="100%" gridTemplateColumns="48px 1fr" height={height}>
      <DeleteNodeDialog.render />
      <DeleteEdgeDialog.render />
      <RFMenu isDisabled={isSubWorkzone} menuItems={menuItems} height={height} />
      <Box width="100%" height={height}>
        <RFRender
          nodes={nodes}
          edges={edges}
          onInit={setInstance}
          onNodesChange={handleNodeChanges}
          onEdgesChange={handleEdgeChanges}
          onConnect={handleOnConnect}
          onConnectEnd={handleOnConnectDrop}
          onBeforeDelete={handleOnBeforeDelete}
          onReconnect={handleOnReconnect}
          onDrop={handleOnCreate}
        />
      </Box>
    </Grid>
  );
};

export const RFWorkZone = observer(RFWorkZoneFC);
