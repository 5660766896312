import {Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

import {ResetModel, SampleDetails, TSampleDetailsModel} from 'core/models';
import * as BASIC_MOCKS from 'core/mocks/samples/sample_1a/sample_1a_basic';
import * as MODEL_MOCKS from 'core/mocks/samples/sample_1a/sample_1a_model';

const ProjectSamples = types
  .compose(
    ResetModel,
    types.model('ProjectSamples', {
      samples: types.optional(types.array(SampleDetails), [])
    })
  )
  .actions((self) => ({
    // TODO: only for dev purpose should be removed
    afterCreate() {
      self.samples.push(
        SampleDetails.create({
          id: uuidv4(),
          name: `Тестовая проба ${self.samples.length + 1}`,
          jsonSchemas: [
            {
              id: uuidv4(),
              name: 'Базовые',
              uiSchema: BASIC_MOCKS.UI_SCHEMA_SAMPLE_1A_BASIC,
              schema: BASIC_MOCKS.SCHEMA_SAMPLE_1A_BASIC,
              formData: BASIC_MOCKS.FORM_DATA_SAMPLE_1A_BASIC
            },
            {
              id: uuidv4(),
              name: 'Модель',
              uiSchema: MODEL_MOCKS.UI_SCHEMA_SAMPLE_1A_MODEL,
              schema: MODEL_MOCKS.SCHEMA_SAMPLE_1A_MODEL,
              formData: MODEL_MOCKS.FORM_DATA_SAMPLE_1A_MODEL
            }
          ]
        })
      );
    },
    fetch(projectId: string): void {
      // TODO
      console.info(projectId);
    },
    createSample(): void {
      self.samples.push(
        SampleDetails.create({
          id: uuidv4(),
          name: `Тестовая проба ${self.samples.length + 1}`,
          jsonSchemas: [
            {
              id: uuidv4(),
              name: 'Базовые',
              uiSchema: BASIC_MOCKS.UI_SCHEMA_SAMPLE_1A_BASIC,
              schema: BASIC_MOCKS.SCHEMA_SAMPLE_1A_BASIC,
              formData: BASIC_MOCKS.FORM_DATA_SAMPLE_1A_BASIC
            },
            {
              id: uuidv4(),
              name: 'Модель',
              uiSchema: MODEL_MOCKS.UI_SCHEMA_SAMPLE_1A_MODEL,
              schema: MODEL_MOCKS.SCHEMA_SAMPLE_1A_MODEL,
              formData: MODEL_MOCKS.FORM_DATA_SAMPLE_1A_MODEL
            }
          ]
        })
      );
    },
    hasSample(elementId: string): boolean {
      return self.samples.some((e) => e.id === elementId);
    },
    findSample(elementId: string): TSampleDetailsModel | undefined {
      return self.samples.find((e) => e.id === elementId);
    }
  }))
  .views(() => ({}));

export type TProjectSamplesModel = Instance<typeof ProjectSamples>;

export {ProjectSamples};
