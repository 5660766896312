import {Grid, GridItem} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FC, useCallback, useEffect} from 'react';
import {useParams} from 'react-router';
import {EStructureItem} from '@progress-fe/core';

import {ProjectLayout} from 'ui-kit';
import {useStore} from 'core/hooks';

import {SamplesForms, SamplesStructure} from './components';

const SamplesAndBlendsSceneFC: FC = () => {
  const {samplesAndBlendsStore} = useStore();
  const {uiState, projectSamples, projectBlends} = samplesAndBlendsStore;

  const {id, checkpointId} = useParams();

  useEffect(() => {
    if (!!id && !!checkpointId) {
      samplesAndBlendsStore.initProject(id, checkpointId).then();
    }
    return () => {
      samplesAndBlendsStore.uninitialize();
    };
  }, [id, checkpointId, samplesAndBlendsStore]);

  const handleCreateEntity = useCallback(
    (entityType: EStructureItem): void => {
      if (entityType === EStructureItem.Sample) {
        projectSamples.createSample();
        return;
      }
      projectBlends.createBlend();
    },
    [projectBlends, projectSamples]
  );

  const handleSelectEntity = useCallback(
    (entityId: string, entityType: EStructureItem): void => {
      uiState.select(entityType, entityId);
    },
    [uiState]
  );

  return (
    <ProjectLayout project={samplesAndBlendsStore} onRunProject={samplesAndBlendsStore.run}>
      <Grid position="relative" h="100%" gridTemplateColumns={'280px 440px 1fr'}>
        {/* PROJECT ITEMS: SETTINGS, ELEMENTS, MODELS ... */}
        <GridItem bg="bg" borderRight="1px" borderColor="border">
          <SamplesStructure
            samples={projectSamples.samples}
            blends={projectBlends.blends}
            selectedId={uiState.entityId}
            onSelectEntity={handleSelectEntity}
            onCreateModel={handleCreateEntity}
          />
        </GridItem>

        {/* SELECTED PROJECT ITEM */}
        <GridItem
          bg="bg"
          borderRight="1px"
          borderColor="border"
          overflowY={uiState.entityType === EStructureItem.Settings ? 'hidden' : 'auto'}
        >
          <SamplesForms
            sampleInstance={samplesAndBlendsStore.selectedSample}
            blendInstance={samplesAndBlendsStore.selectedBlend}
          />
        </GridItem>

        {/* SHOW STRUCTURE, CALCULATION OR RESULTS */}
        <GridItem>
          <></>
        </GridItem>
      </Grid>
    </ProjectLayout>
  );
};

export const SamplesAndBlendsScene = observer(SamplesAndBlendsSceneFC);
