import {FC} from 'react';
import {Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FlexDivider} from '@progress-fe/ui-kit';
import {EStructureItem, useT} from '@progress-fe/core';

import {TBlendDetailsModel, TSampleDetailsModel} from 'core/models';
import {ProjectEntitiesList} from 'ui-kit';

// TODO: unknown should be enum of available types
interface IProps {
  samples: TSampleDetailsModel[];
  blends: TBlendDetailsModel[];
  selectedId: string | null;
  onSelectEntity: (entityId: string, type: EStructureItem) => void;
  onCreateModel: (type: EStructureItem) => void;
}

const SamplesStructureFC: FC<IProps> = ({
  samples,
  blends,
  selectedId,
  onSelectEntity,
  onCreateModel
}) => {
  const {t} = useT();

  return (
    <Grid gridTemplateRows={'1fr 8px 1fr'} h="var(--height-layout)">
      <ProjectEntitiesList
        title={t('common.samples')}
        entities={samples}
        noEntitiesText={t('common.noSamples')}
        icon="Sample"
        menuItems={[{id: '1', name: 'test', items: [{id: '1', name: 'test'}]}]}
        selectedId={selectedId}
        onCreateModel={() => onCreateModel(EStructureItem.Sample)}
        onSelect={(id) => onSelectEntity(id, EStructureItem.Sample)}
      />

      <FlexDivider withDots />

      <ProjectEntitiesList
        title={t('common.blends')}
        entities={blends}
        noEntitiesText={t('common.noBlends')}
        icon="Blend"
        selectedId={selectedId}
        menuItems={[{id: '1', name: 'test', items: [{id: '1', name: 'test'}]}]}
        onCreateModel={() => onCreateModel(EStructureItem.Blend)}
        onSelect={(id) => onSelectEntity(id, EStructureItem.Blend)}
      />
    </Grid>
  );
};

export const SamplesStructure = observer(SamplesStructureFC);
