import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';
import {ItemListHeader} from '@progress-fe/ui-kit';

import {StructureItem} from 'ui-kit';
import {ETaskType} from 'core/enums';

interface IProps {
  taskType: ETaskType;
  isSelected: boolean;
  onSelect: () => void;
}

const TasksListFC: FC<IProps> = ({taskType, isSelected, onSelect}) => {
  const {t} = useT();

  return (
    <Box overflowY="hidden">
      <ItemListHeader title={t('common.task')} />
      <Box overflowY="auto" h="calc(100% - 40px)">
        <StructureItem
          item={{
            uuid: '',
            icon: 'Target',
            isActive: isSelected,
            name: t(`enum.taskType.${taskType}`)
          }}
          onClick={onSelect}
        />
      </Box>
    </Box>
  );
};

export const TasksList = observer(TasksListFC);
