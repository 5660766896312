import {useCallback, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router';
import {Grid, GridItem} from '@chakra-ui/react';
import {EStructureItem} from '@progress-fe/core';
import {useResize} from '@progress-fe/ui-kit';

import {useStore} from 'core/hooks';
import {LogViewer, ProjectLayout} from 'ui-kit';
import {ProjectJsFormConfigContext} from 'core/context/ProjectJsFormConfigContext';

import {ProjectForms, ProjectStructure, ProjectZones} from './components';

const TechProcessSceneFC = () => {
  const {techProcessStore} = useStore();
  const {uiState, logger} = techProcessStore;

  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const {id, checkpointId} = useParams();

  useEffect(() => {
    if (!!id && !!checkpointId) {
      techProcessStore.initProject(id, checkpointId).then();
    }
    return () => {
      techProcessStore.uninitialize();
    };
  }, [id, checkpointId, techProcessStore]);

  useResize(ref, () => logger.close());

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <ProjectJsFormConfigContext>
      <ProjectLayout
        project={techProcessStore}
        isRunDisabled={techProcessStore.isSubWorkzone}
        onRunProject={techProcessStore.run}
      >
        <Grid
          ref={ref}
          position="relative"
          h="var(--height-layout)"
          gridTemplateColumns={isExpanded ? '1fr' : '280px 440px 1fr'}
        >
          {!isExpanded && (
            <>
              {/* PROJECT ITEMS: SETTINGS, ELEMENTS, MODELS */}
              <GridItem bg="bg" borderRight="1px" borderColor="border">
                <ProjectStructure />
              </GridItem>

              {/* SELECTED PROJECT ITEM */}
              <GridItem
                bg="bg"
                borderRight="1px"
                borderColor="border"
                overflowY={uiState.entityType === EStructureItem.Settings ? 'hidden' : 'auto'}
              >
                <ProjectForms />
              </GridItem>
            </>
          )}

          {/* SHOW STRUCTURE, CALCULATION OR RESULTS */}
          <GridItem position={'relative'}>
            <ProjectZones isExpanded={isExpanded} toggleIsExpanded={toggleIsExpanded} />
            {logger.isOpen && <LogViewer logPoints={logger.points} onClose={logger.toggleOpen} />}
          </GridItem>
        </Grid>
      </ProjectLayout>
    </ProjectJsFormConfigContext>
  );
};

export const TechProcessScene = observer(TechProcessSceneFC);

export default TechProcessScene;
