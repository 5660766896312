import {FC, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {Input, Svg, useClickOutside, useDebouncedCallback} from '@progress-fe/ui-kit';
import {
  Box,
  Flex,
  Step,
  IconButton,
  StepStatus,
  StepIndicator,
  StepSeparator
} from '@chakra-ui/react';

import {ICheckpointInfo} from 'core/interfaces';

interface IProps {
  index: number;
  checkpoint: ICheckpointInfo;
  isHovered: boolean;
  isActive: boolean;
  isDeletable: boolean;
  onSwitch: (uuid: string) => void;
  onDelete: (uuid: string) => void;
  onRename: (uuid: string, name: string) => void;
  onHover: (index: number | null) => void;
}

const DELAY_MS = 250;

const CheckpointStepFC: FC<IProps> = ({
  index,
  isActive,
  isHovered,
  isDeletable,
  checkpoint,
  onSwitch,
  onRename,
  onDelete,
  onHover
}) => {
  const stepRef = useRef<HTMLDivElement | null>(null);

  const debouncedOnRename = useDebouncedCallback(onRename, DELAY_MS, []);

  useClickOutside(stepRef, () => {
    if (isHovered) onHover(null);
  });

  return (
    <Step style={{position: 'relative'}}>
      <StepIndicator
        sx={{zIndex: 1}}
        onMouseEnter={() => onHover(index)}
        onClick={() => {
          if (!isActive) {
            onSwitch(checkpoint.uuid);
          }
        }}
      >
        <StepStatus />
      </StepIndicator>
      <StepSeparator style={{zIndex: 1}} />

      {!isActive && !isHovered && (
        <Box
          bg="transparent"
          position="absolute"
          borderRadius="4px"
          width="32px"
          height="32px"
          left="-10px"
          top="-10px"
          zIndex={0}
          onMouseEnter={() => onHover(index)}
        />
      )}

      {(isActive || isHovered) && (
        <Box
          bg="accent10"
          position="absolute"
          borderRadius="4px"
          width="32px"
          height="32px"
          left="-10px"
          top="-10px"
          zIndex={0}
          _hover={{cursor: 'pointer'}}
          onMouseEnter={() => onHover(index)}
        />
      )}

      {isHovered && (
        <Box
          ref={stepRef}
          bg="accent10"
          position="absolute"
          borderTopRightRadius="4px"
          borderBottomRightRadius="4px"
          width="176px"
          height="32px"
          left="20px"
          top="-10px"
        >
          <Flex alignItems={'center'} gap={'4px'} padding={'4px 5px 0 0'}>
            <Input
              size="xs"
              variant="outline"
              value={checkpoint.name}
              placeholder="Название"
              onChange={(name) => debouncedOnRename(checkpoint.uuid, name)}
              sx={{width: '140px'}}
            />
            <Box>
              <IconButton
                variant="tr"
                size="smSquare"
                aria-label=""
                isDisabled={isDeletable}
                icon={<Svg size="s12" name="Bin" />}
                onClick={() => onDelete(checkpoint.uuid)}
              />
            </Box>
          </Flex>
        </Box>
      )}
    </Step>
  );
};

export const CheckpointStep = observer(CheckpointStepFC);
