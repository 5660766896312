import {EElement} from '@progress-fe/core';

import {TElementDetailsSnapshotIn} from 'core/models';
import * as V_100_b from 'core/mocks/projects/Separators/elements/2_phase_separator/V_100_basic';
import * as K_100_b from 'core/mocks/projects/Separators/elements/compressor/K_100_basic';
import * as K_100_c from 'core/mocks/projects/Separators/elements/compressor/K_100_characteristics';
import * as K_100_p from 'core/mocks/projects/Separators/elements/compressor/K_100_performance';

export const SEPARATOR_ELEMENTS_LIST: {
  projectId: string;
  projectName: string;
  items: TElementDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами (моковый проект)',
    items: [
      {
        uuid: '196C89E0-0000-0000-0000-000000000001',
        type: EElement.TwoPhaseSeparatorElement,
        name: 'V-100',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000001',
            name: 'Базовые',
            uiSchema: V_100_b.UI_SCHEMA_2PHASE_SEPARATOR_BASIC,
            schema: V_100_b.SCHEMA_2PHASE_SEPARATOR_BASIC,
            formData: V_100_b.FORM_DATA_2PHASE_SEPARATOR_BASIC
          }
        ]
      },
      {
        uuid: '196C89E0-0000-0000-0000-000000000002',
        type: EElement.CompressorElement,
        name: 'K-100',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000002',
            name: 'Базовые',
            uiSchema: K_100_b.UI_SCHEMA_COMPRESSOR,
            schema: K_100_b.SCHEMA_COMPRESSOR,
            formData: K_100_b.FORM_DATA_COMPRESSOR
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000003',
            name: 'Характеристики',
            uiSchema: K_100_c.UI_SCHEMA_COMPRESSOR_CHARACTERISTICS,
            schema: K_100_c.SCHEMA_COMPRESSOR_CHARACTERISTICS,
            formData: K_100_c.FORM_DATA_COMPRESSOR_CHARACTERISTICS
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000004',
            name: 'Производительность',
            uiSchema: K_100_p.UI_SCHEMA_COMPRESSOR_PERFORMANCE,
            schema: K_100_p.SCHEMA_COMPRESSOR_PERFORMANCE,
            formData: K_100_p.FORM_DATA_COMPRESSOR_PERFORMANCE
          }
        ]
      },
      {
        uuid: '196C89E0-0000-0000-0000-000000000003',
        type: EElement.CoolerElement,
        name: 'E-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-0000-0000-0000-000000000004',
        type: EElement.ValveElement,
        name: 'VLV-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-0000-0000-0000-000000000005',
        type: EElement.ThreePhaseSeparatorElement,
        name: 'V-101',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0100-Q',
        type: EElement.EnergyFlowElement,
        name: 'Q-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0101-Q',
        type: EElement.EnergyFlowElement,
        name: 'Q-101',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0001-M',
        type: EElement.MaterialFlowElement,
        name: '1',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0002-M',
        type: EElement.MaterialFlowElement,
        name: '2',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0003-M',
        type: EElement.MaterialFlowElement,
        name: '3',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0004-M',
        type: EElement.MaterialFlowElement,
        name: '4',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0005-M',
        type: EElement.MaterialFlowElement,
        name: '5',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0006-M',
        type: EElement.MaterialFlowElement,
        name: '6',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0007-M',
        type: EElement.MaterialFlowElement,
        name: '7',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0008-M',
        type: EElement.MaterialFlowElement,
        name: '8',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0009-M',
        type: EElement.MaterialFlowElement,
        name: '9',
        lastUpdated: new Date(),
        jsonSchemas: []
      }
    ]
  }
];
