import {FC, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Flex, Center, Stepper, IconButton, useSteps} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';

import {ICheckpointInfo} from 'core/interfaces';

import {CheckpointStep} from './components';

interface IProps {
  activeUuid: string;
  checkpoints: ICheckpointInfo[];
  onSwitch: (uuid: string) => void;
  onDelete: (uuid: string) => void;
  onRename: (uuid: string, name: string) => void;
  onCreate: () => void;
}

const HISTORY_MAX_COUNT = 10;
const STEP_INDICATOR_SIZE = 12;
const STEP_SEPARATOR_SIZE = 16;

const CheckpointBarFC: FC<IProps> = ({
  activeUuid,
  checkpoints,
  onSwitch,
  onCreate,
  onRename,
  onDelete
}) => {
  const {activeStep, setActiveStep} = useSteps({count: checkpoints.length});

  const [hoveredStep, setHoveredStep] = useState<number | null>(null);

  useEffect(() => {
    setActiveStep(checkpoints.findIndex((c) => c.uuid === activeUuid));
  }, [activeUuid, checkpoints, setActiveStep]);

  const steps = useMemo(() => {
    return checkpoints.map((c) => c.uuid);
  }, [checkpoints]);

  const stepperSize = useMemo(() => {
    return steps.length * STEP_INDICATOR_SIZE + (steps.length - 1) * STEP_SEPARATOR_SIZE;
  }, [steps.length]);

  return (
    <Flex flexDirection="column" gap="8px" pt="12px">
      <Center p="6px 0">
        <Stepper
          gap="0"
          size="sm"
          index={activeStep}
          orientation="vertical"
          height={`${stepperSize}px`}
        >
          {steps.map((_, index) => (
            <CheckpointStep
              key={index}
              index={index}
              checkpoint={checkpoints[index]}
              isHovered={index === hoveredStep}
              isActive={index === activeStep}
              isDeletable={checkpoints.length === 1}
              onHover={setHoveredStep}
              onSwitch={onSwitch}
              onRename={onRename}
              onDelete={onDelete}
            />
          ))}
        </Stepper>
      </Center>

      <Center>
        <IconButton
          size="sm"
          aria-label=""
          variant="ghostTr"
          icon={<Svg name="Plus" />}
          isDisabled={checkpoints.length >= HISTORY_MAX_COUNT}
          onClick={onCreate}
        />
      </Center>
    </Flex>
  );
};

export const CheckpointBar = observer(CheckpointBarFC);
