import {FC} from 'react';
import {Box, Center} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {JsFormName, Loader} from '@progress-fe/ui-kit';

import {TJsonSchemaModel} from 'core/models';

import {IJsonSchemaTab, JsTabsForm} from './components';

interface IProps {
  uuid: string;
  name: string;
  isLoading?: boolean;
  lastUpdated: Date;
  jsonSchemas: Array<TJsonSchemaModel>;
  onUpdateFormData: (uuid: string, jsonSchema: string, formData: unknown) => Promise<void>;
}

const BaseJsFormsFC: FC<IProps> = ({
  uuid,
  name,
  isLoading,
  jsonSchemas,
  lastUpdated,
  onUpdateFormData
}) => {
  const tabs: IJsonSchemaTab[] = jsonSchemas.map((jsonSchema, index) => ({
    index: index,
    name: jsonSchema.name,
    jsonSchema
  }));

  return (
    <Box>
      <JsFormName name={name} />

      {isLoading ? (
        <Center p="100px 0 0">
          <Loader />
        </Center>
      ) : (
        <JsTabsForm
          tabs={tabs}
          lastUpdated={lastUpdated}
          onChange={(formData, tabId: number) => {
            onUpdateFormData(uuid, tabs[tabId].jsonSchema.id, formData).then();
          }}
        />
      )}
    </Box>
  );
};

export const BaseJsForms = observer(BaseJsFormsFC);
