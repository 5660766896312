import {useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {ITab, JsForm} from '@progress-fe/ui-kit';
import {Tabs, TabList, Tab, TabPanels, TabPanel} from '@chakra-ui/react';
import {hasValue} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {TJsonSchemaModel} from 'core/models';

export interface IJsonSchemaTab extends ITab {
  jsonSchema: TJsonSchemaModel;
}

interface IProps<T> {
  tabs: IJsonSchemaTab[];
  lastUpdated?: Date;
  onChange: (formData: T, tabIdx: number, changedFieldPath?: string) => void;
}

const JsTabsFormFC = <T,>({tabs, lastUpdated, onChange}: IProps<T>) => {
  const {projectStore} = useStore();

  const [tabIndex, setTabIndex] = useState(0);
  const withTabs = useMemo(() => tabs.length > 1, [tabs.length]);

  useEffect(() => {
    if (hasValue(projectStore?.uiState?.tabIndex)) {
      const index = projectStore.uiState.tabIndex;
      setTabIndex(tabs.length - 1 >= index ? index : 0);
    }
  }, [projectStore?.uiState?.tabIndex, tabs.length]);

  return (
    <Tabs variant="soft-rounded" index={tabIndex} onChange={projectStore?.selectTabIndex}>
      {withTabs && (
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.index} isDisabled={tab.isDisabled}>
              {tab.name}
            </Tab>
          ))}
        </TabList>
      )}

      <TabPanels>
        {tabs.map(({jsonSchema}, index) => (
          <TabPanel key={`${index}`}>
            <JsForm
              key={`${index}_${lastUpdated?.toString()}`}
              schema={jsonSchema.schema}
              uiSchema={jsonSchema.uiSchema}
              formData={jsonSchema.formData as T}
              onChange={(formData, changedFieldPath) => {
                onChange(formData, index, changedFieldPath);
              }}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const JsTabsForm = observer(JsTabsFormFC);
