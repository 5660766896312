import {Edge, Node} from '@xyflow/react';
import {v4 as uuidv4} from 'uuid';
import {ERFEdge, ERFElement, TRFEdgeDataConfig, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';

export const CAT_CRACKING_1_SUB_WORK_ZONE: {
  projectUuid: string;
  elementUuid: string;
  nodes: Node<TRFWorkZoneDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    /* FCC-100 1 */
    projectUuid: '47CB2F0C-0000-0000-0000-000000000002',
    elementUuid: '196C89E0-0000-0000-0000-000000000001',
    nodes: [
      {
        id: 'SUB_GROUP_1',
        type: ERFElement.GroupDashed,
        data: {
          style: {width: 390, height: 560}
        },
        position: {x: 200, y: 80},
        connectable: false,
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-00000000SUB1',
        type: ERFElement.Regenerator,
        data: {
          elementName: 'Регенератор',
          templateCode: 'default',
          isSubElement: true
        },
        position: {x: 29.5, y: 220},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-00000000SUB2',
        type: ERFElement.Reactor,
        data: {
          elementName: 'Реактор',
          templateCode: 'default',
          isSubElement: true
        },
        position: {x: 150, y: 209.5},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0004-M', // 4 - Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '4',
          isSubElement: false
        },
        position: {x: 105, y: 555},
        connectable: false,
        deletable: false
      },
      {
        id: '0004R-M', // 4R - Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '4R',
          isSubElement: false
        },
        position: {x: 105, y: 490},
        connectable: false,
        deletable: false
      },
      {
        id: '0005-M', // 5 - Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '5',
          isSubElement: false
        },
        position: {x: 635, y: 91},
        connectable: false,
        deletable: false
      },
      {
        id: '0006-M', // 6- Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '6',
          isSubElement: false
        },
        position: {x: 635, y: 170},
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-IN', // DOT 1 - IN
        type: ERFElement.DotMaterial,
        data: {
          isNotElement: true,
          isSubElement: true
        },
        position: {x: -6, y: 490},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0002-DOT-IN', // DOT 2 - IN
        type: ERFElement.DotMaterial,
        data: {
          isNotElement: true,
          isSubElement: true
        },
        position: {x: -6, y: 425},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-СЫРЬЁ', // FLOW - СЫРЬЁ
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Сырьё',
          isSubElement: true
        },
        position: {x: 41, y: 475},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-РЕЦ-ПОТОК', // FLOW - РЕЦ-ПОТОК
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Рецирк. поток',
          isSubElement: true
        },
        position: {x: 84, y: 410},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-IN', // FLOW - IN
        type: ERFElement.FlowMaterial,
        data: {
          isRotate: true,
          elementName: 'IN',
          isSubElement: true
        },
        position: {x: 55, y: 345},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-OUT', // FLOW - OUT
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'OUT',
          isSubElement: true
        },
        position: {x: 98, y: 232.5},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ДЫМ-ГАЗЫ', // FLOW - Дымовые газы
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Дым. газы',
          isSubElement: true
        },
        position: {x: 298, y: 11},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-1', // DOT 1 - OUT
        type: ERFElement.DotMaterial,
        data: {
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 382, y: 26},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-2', // DOT 2 - OUT
        type: ERFElement.DotMaterial,
        data: {
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 382, y: 105},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ПРОД-РЕАКТОРА', // FLOW - Продукты из реактора
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Продукты из реактора',
          isSubElement: true
        },
        position: {x: 298, y: 90},
        parentId: 'SUB_GROUP_1',
        connectable: false,
        deletable: false
      }
    ],
    edges: [
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0004-M', // 4 - Element from MAIN workzone
        target: '0001-DOT-IN', // DOT 1 - IN
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0004R-M', // 4R - Element from MAIN workzone
        target: '0002-DOT-IN', // DOT 2 - IN
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0001-DOT-IN', // DOT 1 - IN
        target: '0000-SUB-СЫРЬЁ', // FLOW - СЫРЬЁ
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0002-DOT-IN', // DOT 2 - IN
        target: '0000-SUB-РЕЦ-ПОТОК', // FLOW - РЕЦ-ПОТОК
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-СЫРЬЁ', // FLOW - СЫРЬЁ
        target: '196C89E0-0000-0000-0000-00000000SUB2', // Реактор
        targetHandle: 'target_material_REACTOR_IN',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-РЕЦ-ПОТОК', // FLOW - РЕЦ-ПОТОК
        target: '196C89E0-0000-0000-0000-00000000SUB2', // Реактор
        targetHandle: 'target_material_REACTOR_IN_RECYCLE',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB2', // Реактор
        target: '0000-SUB-IN', // FLOW - IN
        sourceHandle: 'source_material_REACTOR_TO_REGENERATOR',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-IN', // FLOW - IN
        target: '196C89E0-0000-0000-0000-00000000SUB1', // Регенератор
        targetHandle: 'target_material_REGENERATOR_IN',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB1', // Регенератор
        target: '0000-SUB-OUT', // FLOW - OUT
        sourceHandle: 'source_material_REGENERATOR_TO_REACTOR',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-OUT', // FLOW - OUT
        target: '196C89E0-0000-0000-0000-00000000SUB2', // Реактор
        targetHandle: 'target_material_REACTOR_TO_REACTOR',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB1', // Регенератор
        target: '0000-SUB-ДЫМ-ГАЗЫ', // FLOW - Дымовые газы
        sourceHandle: 'source_material_REGENERATOR_OUT',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ДЫМ-ГАЗЫ', // FLOW - Дымовые газы
        target: '0001-DOT-OUT-1', // DOT 1 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0001-DOT-OUT-1', // DOT 1 - OUT
        target: '0005-M', // 5 - Element from MAIN workzone
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB2', // Реактор
        target: '0000-SUB-ПРОД-РЕАКТОРА', // FLOW - Продукты из реактора
        sourceHandle: 'source_material_REACTOR_OUT',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ПРОД-РЕАКТОРА', // FLOW - Продукты реактора
        target: '0001-DOT-OUT-2', // DOT 2 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0001-DOT-OUT-2', // DOT 2 - OUT
        target: '0006-M', // 6 - Element from MAIN workzone
        deletable: false
      }
    ]
  }
];
