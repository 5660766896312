import {ProjectOut, CalculationStatusEnum, ProjectTypeEnum} from 'api';

/* ELEMENTS */
import {SEPARATOR_ELEMENTS_LIST} from './Separators/elements.mocks';
import {CAT_CRACKING_1_ELEMENTS_LIST} from './CatCracking_1/elements.mocks';
import {CAT_CRACKING_2A_ELEMENTS_LIST} from './CatCracking_2A/elements.mocks';
/* WORKZONES */
import {SEPARATOR_WORKZONE_LIST} from './Separators/workzone.mocks';
import {CAT_CRACKING_1_WORKZONE_LIST} from './CatCracking_1/workzone.mocks';
import {CAT_CRACKING_2A_WORKZONE_LIST} from './CatCracking_2A/workzone.mocks';
/* SUB WORKZONES */
import {CAT_CRACKING_1_SUB_WORK_ZONE} from './CatCracking_1/workzone.sub.mocks';
import {CAT_CRACKING_2A_SUB_WORK_ZONE} from './CatCracking_2A/workzone.sub.mocks';

export const PROJECT_LIST: ProjectOut[] = [
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000001',
    name: 'Схема с сепараторами (моковый проект)',
    type: ProjectTypeEnum.TechProcess,
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb3',
        name: 'Поинт 1',
        calcStatus: CalculationStatusEnum.NoCalcResult,
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000002',
    name: 'Кат. крекинг 1 (моковый проект)',
    type: ProjectTypeEnum.TechProcess,
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb4',
        name: 'Поинт 1',
        calcStatus: CalculationStatusEnum.NoCalcResult,
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000003',
    name: 'Кат. крекинг 2A (моковый проект)',
    type: ProjectTypeEnum.TechProcess,
    updatedAt: new Date(),
    createdAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb4',
        name: 'Поинт 1',
        calcStatus: CalculationStatusEnum.NoCalcResult,
        lastSavingDt: new Date()
      }
    ]
  }
];

export const ELEMENTS_LIST = [
  ...SEPARATOR_ELEMENTS_LIST,
  ...CAT_CRACKING_1_ELEMENTS_LIST,
  ...CAT_CRACKING_2A_ELEMENTS_LIST
];

export const WORKZONES_LIST = [
  ...SEPARATOR_WORKZONE_LIST,
  ...CAT_CRACKING_1_WORKZONE_LIST,
  ...CAT_CRACKING_2A_WORKZONE_LIST
];

export const SUB_WORKZONES_LIST = [
  ...CAT_CRACKING_1_SUB_WORK_ZONE,
  ...CAT_CRACKING_2A_SUB_WORK_ZONE
];
