import {flow, Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EntityDetails} from 'core/models';
import {RJSFSchemas, TechProcessApi} from 'api';

const ElementDetails = types
  .compose(
    EntityDetails,
    types.model('ElementDetails', {
      subElements: types.optional(types.array(EntityDetails), [])
    })
  )
  .actions((self) => ({
    loadJsonSchemas: flow(function* (projectUuid: string, checkpointUuid: string) {
      if (!self.jsonSchemasRequest.isPending) {
        const response: {[key: string]: RJSFSchemas} = yield self.jsonSchemasRequest.send(
          TechProcessApi.techProcessGetElementSchemas.bind(TechProcessApi),
          {
            elementUuid: self.uuid,
            projectUuid: projectUuid,
            checkpointUuid: checkpointUuid
          }
        );

        if (response) {
          self.setJsonSchemas(response);
        }
      }
    })
  }));

export type TElementDetailsModel = Instance<typeof ElementDetails>;
export type TElementDetailsSnapshotIn = SnapshotIn<typeof ElementDetails>;

export {ElementDetails};
