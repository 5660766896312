import {applySnapshot, cast, flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {LocalStorageHelper} from '@progress-fe/core';
import {IErrorMessage} from '@progress-fe/ui-kit';
import {v4 as uuidv4} from 'uuid';

import {RequestModel, ResetModel, TUiStateSnapshotIn} from 'core/models';
import {PROJECT_LIST} from 'core/mocks/projects/projects.mocks';
import {
  ProjectsApi,
  CheckpointsApi,
  ProjectOut,
  CheckpointOut,
  ResponseStatusCheckpointOut
} from 'api';

import {UiState, Logger} from './models';

const isValidUiState = (data: unknown): data is TUiStateSnapshotIn => {
  return UiState.is(data);
};

const UI_STATE_STORAGE = 'progress:checkpoint-ui-state';

const ProjectBase = types
  .compose(
    ResetModel,
    types.model('ProjectBase', {
      isRunning: false,
      isLoading: false,
      isCheckpointChanging: false,

      checkpointUuid: '',
      checkpointLastSaving: types.maybeNull(types.Date),

      logger: types.optional(Logger, {}),
      uiState: types.optional(UiState, {}),
      projectInfo: types.maybeNull(types.frozen<ProjectOut>()),
      errors: types.optional(types.array(types.frozen<IErrorMessage>()), []),

      checkpointCreateRequest: types.optional(RequestModel, {}),
      checkpointChangeRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {}),
      logsRequest: types.optional(RequestModel, {}),
      runCallbackRequest: types.optional(RequestModel, {}),
      runRequest: types.optional(RequestModel, {})
    })
  )
  .volatile<{
    _lastSaveInterval: NodeJS.Timeout | null;
    _uiStateStorage: LocalStorageHelper<TUiStateSnapshotIn> | null;
  }>(() => ({
    _lastSaveInterval: null,
    _uiStateStorage: null
  }))
  .actions((self) => ({
    _load: flow(function* (projectUuid: string) {
      // FIXME: Temp. Removal
      const mockProject = PROJECT_LIST.find((p) => p.uuid === projectUuid);
      if (mockProject) {
        return mockProject;
      }

      const response: ProjectOut = yield self.fetchRequest.send(
        ProjectsApi.projectsGetProject.bind(ProjectsApi),
        {
          projectUuid
        }
      );

      return response || null;
    })
  }))
  // TODO: Use sockets
  .actions((self) => ({
    _setCheckpointLastSaving(date: Date): void {
      self.checkpointLastSaving = date;
    },
    _clearIntervals() {
      if (self._lastSaveInterval) {
        clearInterval(self._lastSaveInterval);
      }
    },
    _startFetchLastSave(): void {
      self._lastSaveInterval = setInterval(() => {
        self._load(self.projectInfo?.uuid || '').then((project) => {
          if (!!project) {
            const checkpoint = project.checkpoints.find((c) => c.uuid === self.checkpointUuid);
            if (!!checkpoint) {
              this._setCheckpointLastSaving(checkpoint.lastSavingDt);
            }
          }
        });
      }, 10000);
    }
  }))
  .actions((self) => ({
    _getUiStateStorageKey() {
      return `${UI_STATE_STORAGE}_${self.checkpointUuid}`;
    },
    _initAndRestoreUiState() {
      self._uiStateStorage = new LocalStorageHelper(this._getUiStateStorageKey(), isValidUiState);
      const storageValue = self._uiStateStorage.get();
      if (!!storageValue) {
        applySnapshot(self.uiState, storageValue);
      }
    },
    _saveUiState() {
      if (self._uiStateStorage) {
        self._uiStateStorage.store(getSnapshot(self.uiState));
      }
    },
    selectTabIndex(index: number) {
      self.uiState._setTabIndex(index);
      this._saveUiState();
    }
  }))
  .actions((self) => ({
    _baseInit: flow(function* (projectId: string, checkpointId: string) {
      const project: ProjectOut | null = yield self._load(projectId);
      const checkpoint = project?.checkpoints.find((c) => c.uuid === checkpointId);

      if (project && checkpoint) {
        self.projectInfo = project;
        self.checkpointUuid = checkpoint.uuid;
        self.checkpointLastSaving = checkpoint.lastSavingDt;
        self._startFetchLastSave();
        self._initAndRestoreUiState();
      }
    }),
    _setErrors(errors: IErrorMessage[]): void {
      self.errors = cast(errors);
    }
  }))
  .actions((self) => ({
    createCheckpoint: flow(function* () {
      self.isCheckpointChanging = true;

      const response: ResponseStatusCheckpointOut = yield self.checkpointCreateRequest.send(
        CheckpointsApi.checkpointsCreateCheckpoint.bind(CheckpointsApi),
        {
          projectUuid: self.projectInfo?.uuid || '',
          fromCheckpoint: self.checkpointUuid,
          idempotencyKey: uuidv4()
        }
      );

      return response?.data?.uuid || '';
    }),
    renameCheckpoint: flow(function* (uuid: string, name: string) {
      if (self.projectInfo) {
        self.projectInfo = {
          ...self.projectInfo,
          checkpoints: self.projectInfo.checkpoints.map((c) => ({
            ...c,
            name: c.uuid === uuid ? name : c.name
          }))
        };

        yield self.checkpointChangeRequest.send(
          CheckpointsApi.checkpointsUpdateCheckpoint.bind(CheckpointsApi),
          {
            projectUuid: self.projectInfo?.uuid || '',
            checkpointUuid: uuid,
            checkpointInUpdate: {
              name
            }
          }
        );
      }
    }),
    deleteCheckpoint: flow(function* (uuid: string) {
      self.isCheckpointChanging = true;

      yield self.checkpointChangeRequest.send(
        CheckpointsApi.checkpointsDeleteCheckpoint.bind(CheckpointsApi),
        {
          projectUuid: self.projectInfo?.uuid || '',
          checkpointUuid: uuid
        }
      );

      return self.checkpointChangeRequest.isDone;
    })
  }))
  .views((self) => ({
    get projectUuid(): string {
      return self.projectInfo?.uuid || '';
    },
    get checkpoints(): Array<CheckpointOut> {
      return self.projectInfo?.checkpoints || [];
    }
  }));

export type TProjectBaseModel = Instance<typeof ProjectBase>;

export {ProjectBase};
