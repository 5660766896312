import {FC, useMemo} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {JsFormName} from '@progress-fe/ui-kit';

import {IJsonSchemaTab, JsTabsForm} from 'ui-kit/forms/BaseJsForms/components';
import {TJsonSchemaModel, TSampleDetailsModel} from 'core/models';

interface IProps {
  sampleInstance: TSampleDetailsModel;
}

// FIXME: Removal. Use BaseJsForms instead of SampleForms
const SampleFormsFC: FC<IProps> = ({sampleInstance}) => {
  const {jsonSchemas} = sampleInstance;

  const tabs: IJsonSchemaTab[] = useMemo(() => {
    return jsonSchemas.map((jsonSchema, index) => ({
      index: index,
      name: jsonSchema.name,
      jsonSchema
    }));
  }, [jsonSchemas]);

  const handleChangeFormData = (newFormData: unknown, jsonSchema: TJsonSchemaModel) => {
    jsonSchema.updateFormData(newFormData);
  };

  return (
    <Box>
      <JsFormName name={sampleInstance.name} />
      <JsTabsForm
        tabs={tabs}
        onChange={(formData, tabId: number) => {
          handleChangeFormData(formData, tabs[tabId]?.jsonSchema);
        }}
      />
    </Box>
  );
};

export const SampleForms = observer(SampleFormsFC);
