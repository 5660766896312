import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EStructureItem} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {BaseJsForms} from 'ui-kit';

import {TaskForm, BackToEntity, SettingsForms} from './components';

const ProjectFormsFC: FC = () => {
  const {techProcessStore} = useStore();

  const {projectTask, projectElements, projectModels, backToEntity, uiState} = techProcessStore;
  const {selectedModel, selectedResult, selectedReaction} = techProcessStore;
  const {selectedElement, selectedSubElement} = techProcessStore;
  const {entityType, subEntityId} = uiState;

  const selectedEntity = selectedElement || selectedModel || selectedResult || selectedReaction;

  return (
    <Box p="12px 8px" position="relative" h="100%">
      {/* Back link to an entity from a sub entity */}
      {!!selectedEntity && !!subEntityId && (
        <BackToEntity name={selectedEntity.name} onBack={backToEntity} />
      )}

      {/* Settings forms */}
      {entityType === EStructureItem.Settings && <SettingsForms />}

      {/* Element forms */}
      {entityType === EStructureItem.Element && !!selectedElement && !selectedSubElement && (
        <BaseJsForms
          key={selectedElement.uuid}
          uuid={selectedElement.uuid}
          name={selectedElement.name}
          lastUpdated={selectedElement.lastUpdated}
          jsonSchemas={selectedElement.jsonSchemas}
          isLoading={selectedElement.isSchemasLoading}
          onUpdateFormData={projectElements.updateElementFormData}
        />
      )}

      {/* Sub element forms */}
      {entityType === EStructureItem.Element && !!selectedSubElement && (
        <BaseJsForms
          key={selectedSubElement.uuid}
          uuid={selectedSubElement.uuid}
          name={selectedSubElement.name}
          lastUpdated={selectedSubElement.lastUpdated}
          jsonSchemas={selectedSubElement.jsonSchemas}
          isLoading={selectedSubElement.isSchemasLoading}
          onUpdateFormData={projectElements.updateSubElementFormData}
        />
      )}

      {/* Model forms */}
      {entityType === EStructureItem.Model && !!selectedModel && (
        <BaseJsForms
          key={selectedModel.uuid}
          uuid={selectedModel.uuid}
          name={selectedModel.name}
          lastUpdated={selectedModel.lastUpdated}
          jsonSchemas={selectedModel.jsonSchemas}
          isLoading={selectedModel.isSchemasLoading}
          onUpdateFormData={projectModels.updateModelFormData}
        />
      )}

      {/* Reaction forms */}
      {entityType === EStructureItem.Reaction && !!selectedReaction && (
        <BaseJsForms
          key={selectedReaction.uuid}
          uuid={selectedReaction.uuid}
          name={selectedReaction.name}
          lastUpdated={selectedReaction.lastUpdated}
          jsonSchemas={selectedReaction.jsonSchemas}
          isLoading={selectedReaction.isSchemasLoading}
          onUpdateFormData={() => Promise.resolve()}
        />
      )}

      {/* Task forms */}
      {entityType === EStructureItem.Task && <TaskForm taskInstance={projectTask} />}

      {/* Result forms */}
      {entityType === EStructureItem.Result && !!selectedResult && (
        <BaseJsForms
          key={selectedResult.uuid}
          uuid={selectedResult.uuid}
          name={selectedResult.name}
          lastUpdated={selectedResult.lastUpdated}
          jsonSchemas={selectedResult.jsonSchemas}
          isLoading={selectedResult.isSchemasLoading}
          onUpdateFormData={projectModels.updateModelFormData}
        />
      )}
    </Box>
  );
};

export const ProjectForms = observer(ProjectFormsFC);
