import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {JsonSchema} from 'core/models';

const BlendDetails = types
  .model('BlendDetails', {
    id: types.string,
    name: types.string,
    jsonSchemas: types.optional(types.array(JsonSchema), [])
  })
  .actions(() => ({}));

export type TBlendDetailsModel = Instance<typeof BlendDetails>;

export type TBlendDetailsSnapshotIn = SnapshotIn<typeof BlendDetails>;

export {BlendDetails};
