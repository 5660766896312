import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {JsonSchema} from 'core/models';

const SampleDetails = types
  .model('SampleDetails', {
    id: types.string,
    name: types.string,
    jsonSchemas: types.optional(types.array(JsonSchema), [])
  })
  .actions(() => ({}));

export type TSampleDetailsModel = Instance<typeof SampleDetails>;

export type TSampleDetailsSnapshotIn = SnapshotIn<typeof SampleDetails>;

export {SampleDetails};
