import {cast, flow, Instance, types} from 'mobx-state-tree';
import {IRFNodePort, TRFWorkZone} from '@progress-fe/rf-core';
import {EElement} from '@progress-fe/core';
import {XYPosition} from '@xyflow/react';
import {v4 as uuidv4} from 'uuid';

import {
  UpdateOut,
  ElementsOut,
  TechProcessApi,
  ElementClassName,
  CreateElementActionResult
} from 'api';
import {
  Workzone,
  ResetModel,
  RequestModel,
  ElementDetails,
  TElementDetailsModel,
  TEntityDetailsModel
} from 'core/models';
import {
  ELEMENTS_LIST,
  WORKZONES_LIST,
  SUB_WORKZONES_LIST
} from 'core/mocks/projects/projects.mocks';

const ProjectElements = types
  .compose(
    ResetModel,
    types.model('ProjectElements', {
      projectUuid: '',
      checkpointUuid: '',

      elements: types.optional(types.array(ElementDetails), []),
      workzone: types.optional(Workzone, {}),
      subWorkzone: types.optional(Workzone, {}),
      subWorkzoneUuid: types.maybeNull(types.string),

      formDataRequest: types.optional(RequestModel, {}),
      positionRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    hasElement(uuid: string): boolean {
      return self.elements.some((e) => e.uuid === uuid);
    },
    findElement(uuid: string): TElementDetailsModel | undefined {
      return self.elements.find((e) => e.uuid === uuid);
    },
    findSubElement(uuid: string, subUuid: string): TEntityDetailsModel | undefined {
      const element = self.elements.find((e) => e.uuid === uuid);
      return element?.subElements.find((e) => e.uuid === subUuid);
    }
  }))
  .actions((self) => ({
    clearJsonSchemas() {
      self.elements.forEach((element) => {
        element.clearJsonSchemas();
        element.subElements.forEach((subElement) => {
          subElement.clearJsonSchemas();
        });
      });
    },
    async loadJsonSchemasByUuid(uuid: string, subUuid?: string | null) {
      const element = self.findElement(uuid);
      const subElement = !!subUuid ? self.findSubElement(uuid, subUuid) : null;

      if (!!element && !subElement) {
        await element.loadJsonSchemas(self.projectUuid, self.checkpointUuid);
      }

      if (!!element && !!subElement) {
        // TODO: Load sub schemas
      }
    }
  }))
  .actions((self) => ({
    _loadElements: flow(function* () {
      // TODO: Temp. Removal
      const mockElements = ELEMENTS_LIST.find((m) => m.projectId === self.projectUuid)?.items || [];
      if (mockElements.length) {
        self.elements = cast(mockElements);
        return;
      }

      const response: ElementsOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetElements.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!!response?.elements) {
        self.elements = cast(
          response.elements.map((el) => ({
            uuid: el.uuid,
            name: el.name,
            lastUpdated: new Date(),
            type: el.type as EElement
          }))
        );
      }
    }),
    _loadWorkZone: flow(function* () {
      // TODO: Temp. Removal
      const mockWorkZone = WORKZONES_LIST.find((d) => d.projectId === self.projectUuid);
      if (mockWorkZone) {
        self.workzone.nodes = cast(mockWorkZone.nodes);
        self.workzone.edges = cast(mockWorkZone.edges);
        return;
      }

      const response: unknown = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetWorkZone.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!!response) {
        const workZone = response as TRFWorkZone;
        self.workzone.nodes = cast(workZone.nodes);
        self.workzone.edges = cast(workZone.edges);
      }
    })
  }))
  .actions((self) => ({
    // TODO: Implementation
    _loadSubWorkZone: flow(function* (elementUuid: string) {
      const mockSubWorkZone = SUB_WORKZONES_LIST.find(
        (w) => w.projectUuid === self.projectUuid && w.elementUuid === elementUuid
      );

      if (mockSubWorkZone) {
        self.subWorkzoneUuid = elementUuid;
        self.subWorkzone.nodes = cast(mockSubWorkZone.nodes);
        self.subWorkzone.edges = cast(mockSubWorkZone.edges);
      }
    }),
    _clearSubWorkzone(): void {
      self.subWorkzoneUuid = null;
      self.subWorkzone.clear();
    }
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.resetModel();
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;

      yield self._loadElements();
      yield self._loadWorkZone();
    })
  }))
  .actions((self) => ({
    add: flow(function* (elementType: EElement, position?: XYPosition) {
      const response: CreateElementActionResult = yield self.actionRequest.send(
        TechProcessApi.techProcessCreateElement.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          newElement: {
            type: elementType as ElementClassName,
            position: position || {x: 0, y: 0}
          }
        }
      );

      return self.actionRequest.isDone && !!response
        ? ElementDetails.create({
            uuid: response.data.uuid,
            name: response.data.name,
            lastUpdated: new Date(),
            type: elementType
          })
        : null;
    }),
    remove: flow(function* (uuid: string) {
      yield self.actionRequest.send(TechProcessApi.techProcessDeleteElement.bind(TechProcessApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        elementUuid: uuid
      });

      return self.actionRequest.isDone;
    }),
    connect: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      yield self.actionRequest.send(TechProcessApi.techProcessAddConnection.bind(TechProcessApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        bodyTechProcessAddConnection: {
          sourceInfo: {
            elementUuid: from.nodeUuid,
            portCode: from.portCode,
            portNumber: null
          },
          targetInfo: {
            elementUuid: to.nodeUuid,
            portCode: to.portCode,
            portNumber: null
          }
        }
      });

      return self.actionRequest.isDone;
    }),
    disconnect: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      yield self.actionRequest.send(
        TechProcessApi.techProcessRemoveConnection.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          bodyTechProcessRemoveConnection: {
            sourceInfo: {
              elementUuid: from.nodeUuid,
              portCode: from.portCode,
              portNumber: null
            },
            targetInfo: {
              elementUuid: to.nodeUuid,
              portCode: to.portCode,
              portNumber: null
            }
          }
        }
      );

      return self.actionRequest.isDone;
    })
  }))
  .actions((self) => ({
    _updateElementName(uuid: string, name: string) {
      const element = self.findElement(uuid);
      element?.setName(name);
    }
  }))
  .actions((self) => ({
    updateElementFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      const element = self.elements.find((el) => el.uuid === uuid);
      const jsonSchema = element?.jsonSchemas.find((js) => js.id === schemaId);

      if (!!element && !!jsonSchema) {
        jsonSchema.updateFormData(data);

        const response: UpdateOut = yield self.formDataRequest.send(
          TechProcessApi.techProcessUpdateElementInstance.bind(TechProcessApi),
          {
            elementUuid: uuid,
            body: data as object,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid
          }
        );

        if (self.formDataRequest.isDone) {
          if (!!response.name) {
            console.info('Element name was changed.');
            self._updateElementName(uuid, response.name);
          }
          if (!!response.schemas) {
            console.info('Element schemas was changed.');
            element.setJsonSchemas(response.schemas);
          }
          if (!!response.workzone) {
            console.info(`Work zone was changed. Nodes: ${response.workzone.nodes.length}.`);
            console.info(`Work zone was changed. Edges: ${response.workzone.edges.length}.`);
            self.workzone.setNodesAndEdges(response.workzone as TRFWorkZone);
          }
        }
      }
    }),
    // TODO: Implementation
    updateSubElementFormData: flow(function* (subUuid: string, schemaId: string, data: unknown) {
      yield Promise.resolve({subUuid, schemaId, data});
    }),
    updateNodePosition: flow(function* (uuid: string, position: XYPosition) {
      const node = self.workzone.nodes.find((n) => n.id === uuid);
      if (!!node) {
        self.workzone.nodes = cast([
          ...self.workzone.nodes.filter((n) => n.id !== uuid),
          {...node, position}
        ]);

        yield self.positionRequest.send(
          TechProcessApi.techProcessUpdateElementInstancePosition.bind(TechProcessApi),
          {
            elementUuid: uuid,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid,
            position: position
          }
        );
      }
    })
  }))
  .views((self) => ({
    get subWorkzoneElement() {
      return !!self.subWorkzoneUuid ? self.findElement(self.subWorkzoneUuid) || null : null;
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    },
    get isFormDataUpdating(): boolean {
      return self.formDataRequest.isPending;
    }
  }));

export type TProjectElementsModel = Instance<typeof ProjectElements>;

export {ProjectElements};
