import {cast, flow, getSnapshot, types} from 'mobx-state-tree';
import {delay, EElement, EStructureItem, EventEmitter, IReaction} from '@progress-fe/core';
import {IRFNodePort} from '@progress-fe/rf-core';
import {XYPosition} from '@xyflow/react';

import {ELogItemType} from 'core/enums';
import {ILogPoint} from 'core/interfaces';
import {ProjectBase, TEntityDetailsModel} from 'core/models';
import {TechProcessGetTechprocessJournalOrderByEnum} from 'api/generated';
import {
  CalculationTaskRunStatus,
  CallbackRunResponse,
  TechProcessApi,
  TechProcessJournalTaskRunPoint,
  TechProcessJournalTaskRunPointEnum
} from 'api';

import {
  ProjectTask,
  ProjectModels,
  ProjectResults,
  ProjectSettings,
  ProjectElements,
  ProjectReactions
} from './models';

const RUN_DELAY_MS = 1000;

const TechProcessStore = types
  .compose(
    ProjectBase,
    types.model('TechProcessStore', {
      projectSettings: types.optional(ProjectSettings, {}),
      projectModels: types.optional(ProjectModels, {}),
      projectElements: types.optional(ProjectElements, {}),
      projectTask: types.optional(ProjectTask, {}),
      projectResults: types.optional(ProjectResults, {}),
      projectReactions: types.optional(ProjectReactions, {})
    })
  )
  .volatile<{_runWaiter: NodeJS.Timeout | null; _runAttempt: number}>(() => ({
    _runWaiter: null,
    _runAttempt: 0
  }))
  .views((self) => ({
    get isFormsUpdating(): boolean {
      return self.projectElements.isFormDataUpdating || self.projectModels.isFormDataUpdating;
    }
  }))
  .actions((self) => ({
    _clearAllJsonSchemas(): void {
      self.projectModels.clearJsonSchemas();
      self.projectElements.clearJsonSchemas();
    },
    // This is called during first load or after running
    _reloadActiveEntity(): void {
      this._clearAllJsonSchemas();

      const {entityId, subEntityId} = self.uiState;

      if (self.uiState.entityType === EStructureItem.Element) {
        self.projectElements.loadJsonSchemasByUuid(entityId, subEntityId).then();
        if (!!subEntityId) self.projectElements._loadSubWorkZone(entityId).then();
        if (!subEntityId) self.projectElements._clearSubWorkzone();
      } else if (self.uiState.entityType === EStructureItem.Model) {
        self.projectModels.loadJsonSchemasByUuid(entityId).then();
      } else if (self.uiState.entityType === EStructureItem.Task) {
        self.projectTask._loadGraphZone().then();
      }
    },
    // This is called by the event emitter
    _selectEntityById(entityId: string, subEntityId?: string): void {
      this._clearAllJsonSchemas();

      if (self.projectElements.hasElement(entityId)) {
        self.uiState.select(EStructureItem.Element, entityId, subEntityId);
        self.projectElements.loadJsonSchemasByUuid(entityId, subEntityId).then();
      } else if (self.projectModels.hasModel(entityId)) {
        self.uiState.select(EStructureItem.Model, entityId, subEntityId);
        self.projectModels.loadJsonSchemasByUuid(entityId).then();
      } else if (self.projectResults.hasResult(entityId)) {
        self.uiState.select(EStructureItem.Result, entityId, subEntityId);
      } else if (self.projectReactions.hasResult(entityId)) {
        self.uiState.select(EStructureItem.Reaction, entityId, subEntityId);
      }

      self.uiState._setTabIndex(0);
      self._saveUiState();
    },
    // This is called by a structure item
    selectEntityByType(entityType: EStructureItem, uuid?: string | null, subUuid?: string | null) {
      this._clearAllJsonSchemas();
      self.uiState.select(entityType, uuid, subUuid);

      if (entityType === EStructureItem.Element && !!uuid) {
        self.projectElements.loadJsonSchemasByUuid(uuid, subUuid).then();
        if (!!subUuid) self.projectElements._loadSubWorkZone(uuid).then();
        if (!subUuid) self.projectElements._clearSubWorkzone();
      } else if (entityType === EStructureItem.Model && !!uuid) {
        self.projectModels.loadJsonSchemasByUuid(uuid).then();
        self.projectElements._clearSubWorkzone();
      } else if (entityType === EStructureItem.Reaction) {
        self.projectElements._clearSubWorkzone();
      } else if (entityType === EStructureItem.Task) {
        self.projectTask._loadGraphZone().then();
        self.projectElements._clearSubWorkzone();
      } else if (entityType === EStructureItem.Result) {
        self.projectElements._clearSubWorkzone();
      }

      self.uiState._setTabIndex(0);
      self._saveUiState();
    },
    backToEntity(entityId?: string | null) {
      this.selectEntityByType(self.uiState.entityType, entityId || self.uiState.entityId);
    }
  }))
  .actions((self) => ({
    _subscribe() {
      EventEmitter.on('SelectItem', self._selectEntityById);
    },
    _unsubscribe() {
      EventEmitter.off('SelectItem', self._selectEntityById);
    },
    uninitialize() {
      this._unsubscribe();
      self._clearIntervals();
      self.resetModel();
    }
  }))
  .actions((self) => ({
    runElementCallback: flow(function* (callbackCode: string) {
      console.info(`Run callback: code ${callbackCode}, element ${self.uiState.entityId}`);
      if (self.uiState.entityType !== EStructureItem.Element) {
        return null;
      }

      const response: CallbackRunResponse = yield self.runCallbackRequest.send(
        TechProcessApi.techProcessRunElementCallback.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          elementUuid: self.uiState.entityId,
          callbackRunRequest: {
            callbackCode
          }
        }
      );

      return response?.fieldValue || null;
    }),
    runModelCallback: flow(function* (callbackCode: string) {
      console.info(`Run callback: code ${callbackCode}, model ${self.uiState.entityId}`);
      if (self.uiState.entityType !== EStructureItem.Model) {
        return null;
      }

      const response: CallbackRunResponse = yield self.runCallbackRequest.send(
        TechProcessApi.techProcessRunModelCallback.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          modelInstanceUuid: self.uiState.entityId,
          callbackRunRequest: {
            callbackCode
          }
        }
      );

      return response?.fieldValue || null;
    })
  }))
  .actions((self) => ({
    createElement: flow(function* (type: EElement, templateCode?: string, position?: XYPosition) {
      const element = yield self.projectElements.add(type, position);
      if (!!element) {
        yield self.projectElements._loadWorkZone();

        self.projectElements.elements.push(element);
        self.selectEntityByType(EStructureItem.Element, element.uuid);
        return element.uuid;
      } else {
        return null;
      }
    }),
    deleteElement: flow(function* (uuid: string) {
      const isDone = yield self.projectElements.remove(uuid);
      if (isDone) {
        yield self.projectElements._loadWorkZone();
        const {elements} = self.projectElements;
        self.projectElements.elements = cast([...elements.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
      }
    }),
    connectElements: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      const isDone = yield self.projectElements.connect(from, to);
      if (isDone) {
        yield self.projectElements._loadWorkZone();
        const selectedUuid = self.uiState.entityId === from.nodeUuid ? to.nodeUuid : from.nodeUuid;
        self.selectEntityByType(EStructureItem.Element, selectedUuid);
      }
    }),
    disconnectElements: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      const isDone = yield self.projectElements.disconnect(from, to);
      if (isDone) {
        yield self.projectElements._loadWorkZone();
        const selectedUuid = self.uiState.entityId === from.nodeUuid ? to.nodeUuid : from.nodeUuid;
        self.selectEntityByType(EStructureItem.Element, selectedUuid);
      }
    }),
    createModel: flow(function* (uuid: string) {
      const model = yield self.projectModels.addModel(uuid);
      if (!!model) {
        self.projectModels.models.push(model);
        self.selectEntityByType(EStructureItem.Model, model.uuid);
      }
    }),
    deleteModel: flow(function* (uuid: string) {
      const isDone = yield self.projectModels.removeModel(uuid);
      if (isDone) {
        const {models} = self.projectModels;
        self.projectModels.models = cast([...models.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
      }
    }),
    addComponentsToFavorite: flow(function* (componentsIds: string[]) {
      yield self.projectSettings.addToFavorites(self.projectUuid, componentsIds);
    }),
    removeComponentsFromFavorite: flow(function* (componentsIds: string[]) {
      yield self.projectSettings.removeFromFavorites(self.projectUuid, componentsIds);
    })
  }))
  .actions((self) => ({
    _loadLogs: flow(function* () {
      const response: TechProcessJournalTaskRunPoint[] = yield self.logsRequest.send(
        TechProcessApi.techProcessGetTechprocessJournal.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          orderByField: TechProcessJournalTaskRunPointEnum.RunAt,
          orderBy: TechProcessGetTechprocessJournalOrderByEnum.Desc
        }
      );

      if (response?.length) {
        const logs: ILogPoint[] = response.map((point) => ({
          runAt: point.runAt,
          calculationNumber: point.calculationNumber,
          items: point.items.map((item) => ({
            type: item.type as ELogItemType,
            createdAt: item.createdAt,
            message: item.message
          }))
        }));

        self.logger.setPoints(logs);
      } else {
        self.logger.setPoints([]);
      }
    })
  }))
  .actions((self) => ({
    _executeRun: flow(function* () {
      if (self.runRequest.isPending) return;

      console.info('[Run] In progress...');
      const response: CalculationTaskRunStatus = yield self.runRequest.send(
        TechProcessApi.techProcessRunTechprocessCalculationTask.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!!response?.success) {
        self.errors = cast([]);
        self._reloadActiveEntity();
      } else {
        self.errors = cast([{title: 'Запуск', message: 'Неизвестная ошибка.'}]);
      }

      yield self._loadLogs();

      console.info('[Run] Finished.');
      self.isRunning = false;
    })
  }))
  .actions((self) => {
    const actions = {
      _tryRun: flow(function* () {
        self._runAttempt++;
        console.info(`[Run] Start attempt ${self._runAttempt}.`);
        if (!self.isFormsUpdating) {
          if (self._runWaiter) {
            clearInterval(self._runWaiter);
            self._runAttempt = 0;
          }
          yield self._executeRun();
        }
      }),
      run: flow(function* () {
        self.isRunning = true;

        // TODO: Fix waiting for living focus
        yield delay(RUN_DELAY_MS);

        self._runWaiter = setInterval(() => {
          actions._tryRun().then();
        }, RUN_DELAY_MS);
      })
    };
    return actions;
  })
  .actions((self) => ({
    _reloadProjectData: flow(function* () {
      if (!!self.projectInfo && !!self.checkpointUuid) {
        const {uuid} = self.projectInfo;

        yield self.projectSettings.init(uuid);
        yield self.projectModels.init(uuid, self.checkpointUuid);
        yield self.projectElements.init(uuid, self.checkpointUuid);
        yield self.projectTask.init(uuid, self.checkpointUuid);
        self.projectResults.fetch(uuid); // TODO
        yield self._loadLogs();
      }
    })
  }))
  .actions((self) => ({
    initProject: flow(function* (projectId: string, checkpointId: string) {
      self.isLoading = true;
      yield self._baseInit(projectId, checkpointId);

      if (!!self.projectInfo) {
        yield self._reloadProjectData();
        self._reloadActiveEntity();
        self._subscribe();
      }

      self.isLoading = false;
    })
  }))
  .views((self) => ({
    get selectedElement(): TEntityDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Element) return null;
      return self.projectElements.findElement(self.uiState.entityId) || null;
    },
    get selectedSubElement(): TEntityDetailsModel | null {
      const {entityType, entityId, subEntityId} = self.uiState;
      if (entityType !== EStructureItem.Element || !subEntityId) return null;
      return self.projectElements.findSubElement(entityId, subEntityId || '') || null;
    },
    get selectedModel(): TEntityDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Model) return null;
      return self.projectModels.models.find((el) => el.uuid === self.uiState.entityId) || null;
    },
    get selectedResult(): TEntityDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Result) return null;
      return self.projectResults.results.find((el) => el.uuid === self.uiState.entityId) || null;
    },
    get selectedReaction(): TEntityDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Reaction) return null;
      return (
        self.projectReactions.reactions.find((el) => el.uuid === self.uiState.entityId) || null
      );
    }
  }))
  .views((self) => ({
    get reactionsDictionary(): Record<string, IReaction> {
      return self.projectReactions.dictionary;
    }
  }))
  .views((self) => ({
    get isSubWorkzone(): boolean {
      return !!self.projectElements.subWorkzoneUuid;
    },
    get workzone() {
      return this.isSubWorkzone ? self.projectElements.subWorkzone : self.projectElements.workzone;
    },
    get nodeList() {
      return getSnapshot(this.workzone.nodes);
    },
    get edgeList() {
      return getSnapshot(this.workzone.edges);
    },
    get workzoneKey() {
      return `${this.workzone.lastUpdate.toISOString()}_${self.projectElements.subWorkzoneUuid}`;
    }
  }));

export {TechProcessStore};
